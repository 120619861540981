.header-image {
    background-image: url("../img/header.png");
    background-size: cover;
    background-position: center -400px;
    background-repeat: no-repeat;
}

.header-image-h-400 {
    min-height: 400px;
}

.rounded-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.disabled-form-element {
    pointer-events: none;
    cursor: default;
    touch-action: none;
    filter: grayscale(1);
}

ul.custom-profile-checkboxes {
    list-style-type: none;
    padding: 0;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }
  
  ul.custom-profile-checkboxes li {
    margin: 15px;
  }
  
  ul.custom-profile-checkboxes input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  ul.custom-profile-checkboxes label {
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  ul.custom-profile-checkboxes label::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  
  ul.custom-profile-checkboxes label img {
    height: 120px;
    width: 120px;
    object-fit: cover;
  }

  ul.custom-profile-checkboxes label::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    font-size: 10px;
    position: absolute;
    top: 10.5px;
    left: 10px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  
  ul.custom-profile-checkboxes input:checked + label {
    border-color: #ddd;
  }
  
  ul.custom-profile-checkboxes input:checked + label::after {
    opacity: 1;
    visibility: visible;
  }
  
  ul.custom-profile-checkboxes input:checked + label img {

  }

@media only screen and (max-width: 1600px) {
    .header-image {
        background-position: center -300px;
    }
}

@media only screen and (max-width: 1366px) {
    .header-image {
        background-position: center -200px;
    }
}

@media only screen and (max-width: 1200px) {
    .header-image {
        background-position: center -100px;
    }
}